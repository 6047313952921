export const eventClickerList = {
  getSmsCode: {
    event: 'onclick',
    options: {
      event: 'number_click',
      params: {
        event_category: 'number',
        event_action: 'click',
      },
    },
    listener: '',
  },
  forgotPassword: {
    event: 'onclick',
    options: {
      event: 'forgotten_password_click',
      params: {
        event_category: 'forgotten_password',
        event_action: 'click',
      },
    },
    listener: '',
  },
  phoneCodeSubmit: {
    event: 'oninput',
    options: {
      event: 'kod_number_click',
      params: {
        event_category: 'kod_number',
        event_action: 'click',
      },
    },
    listener: '',
    isValid: event => event.target.value.toString().replace(/\s/g, '').length === 4,
  },
  emailSubmit: {
    event: 'onclick',
    options: {
      event: 'email_click',
      params: {
        event_category: 'email_click',
        event_action: 'click',
      },
    },
    listener: '',
  },
  backToShopping: {
    event: 'onclick',
    options: {
      event: 'pay_back_to_shopping',
      params: { category: 'pay', action: 'back_to_shopping' },
    },
    listener: '',
  },
  deliveryCourierStreet: {
    event: 'onblur',
    options: {
      event: 'delivery_courier_street',
      params: {
        category: 'delivery',
        action: 'courier',
        label: 'street',
      },
    },
    listener: '',
  },
  deliveryPostStreet: {
    event: 'onblur',
    options: {
      event: 'delivery_post_street',
      params: {
        category: 'delivery',
        action: 'post',
        label: 'street',
      },
    },
    listener: '',
  },
  deliveryPostHome: {
    event: 'onblur',
    options: {
      event: 'delivery_post_home',
      params: {
        category: 'delivery',
        action: 'post',
        label: 'home',
      },
    },
    listener: '',
  },
  deliveryCourierDay: {
    event: 'onclick',
    options: {
      event: 'delivery_courier_day',
      params: {
        category: 'delivery',
        action: 'courier',
        label: 'day',
      },
    },
    listener: '',
  },
  appointmentSelectForm: {
    event: 'onclick',
    options: {
      event: 'appointment.select_form',
    },
    listener: '',
  },
  appointmentSelectBySalon: {
    event: 'onclick',
    options: {
      event: 'appointment.select_by_salon',
      params: {
        category: 'choice_by_salon',
      },
    },
    listener: '',
  },
  appointmentSelectedSalon: {
    event: 'onclick',
    options: {
      event: 'appointment.selected_salon',
      params: {
        category: 'choice_by_salon',
      },
    },
    listener: '',
  },
  appointmentSelectedServiceSalon: {
    event: 'onclick',
    options: {
      event: 'appointment.selected_service',
      params: {
        category: 'choice_by_salon',
      },
    },
    listener: '',
  },
  appointmentSelectedDateSalon: {
    event: 'onclick',
    options: {
      event: 'appointment.selected_date',
      params: {
        category: 'choice_by_salon',
      },
    },
    listener: '',
  },
  appointmentComfirmSalon: {
    event: 'onclick',
    options: {
      event: 'appointment.comfirm',
      params: {
        category: 'choice_by_salon',
      },
    },
    listener: '',
  },
  appointmentSelectBySpecialist: {
    event: 'onclick',
    options: {
      event: 'appointment.select_by_specialist',
      params: {
        category: 'choice_by_specialist',
      },
    },
    listener: '',
  },
  appointmentSelectedSpecialist: {
    event: 'onclick',
    options: {
      event: 'appointment.selected_specialist',
      params: {
        category: 'choice_by_specialist',
      },
    },
    listener: '',
  },
  appointmentSelectedServiceSpecialist: {
    event: 'onclick',
    options: {
      event: 'appointment.selected_service',
      params: {
        category: 'choice_by_specialist',
      },
    },
    listener: '',
  },
  appointmentSelectedDateSpecialist: {
    event: 'onclick',
    options: {
      event: 'appointment.selected_date',
      params: {
        category: 'choice_by_specialist',
      },
    },
    listener: '',
  },
  appointmentComfirmSpecialist: {
    event: 'onclick',
    options: {
      event: 'appointment.comfirm',
      params: {
        category: 'choice_by_specialist',
      },
    },
    listener: '',
  },
  appointmentSelectByDate: {
    event: 'onclick',
    options: {
      event: 'appointment.select_by_date',
      params: {
        category: 'choice_by_date',
      },
    },
    listener: '',
  },
  appointmentSelectedDate: {
    event: 'onclick',
    options: {
      event: 'appointment.selected_date',
      params: {
        category: 'choice_by_date',
      },
    },
    listener: '',
  },
  appointmentSelectedServiceDate: {
    event: 'onclick',
    options: {
      event: 'appointment.selected_service',
      params: {
        category: 'choice_by_date',
      },
    },
    listener: '',
  },
  appointmentSelectedSalonDate: {
    event: 'onclick',
    options: {
      event: 'appointment.selected_salon',
      params: {
        category: 'choice_by_date',
      },
    },
    listener: '',
  },
  appointmentComfirmDate: {
    event: 'onclick',
    options: {
      event: 'appointment.comfirm',
      params: {
        category: 'choice_by_date',
      },
    },
    listener: '',
  },
};
