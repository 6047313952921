/**
 * События аналитики которые обрабатываются на сайте
 *
 * Список событий находится в файле @events.js
 * Для добавления нового события, требуется заполнить обьект в events.js
 * по примеру как сделано до этого и на нужный
 * элемент повесить data-analitycs="key", где key это ключ обьекта в event.js файле
 * События @onclick обрабатываются отдельно в момент клика по элементу, остальные
 * события обрабатываются через eventListener
 */

import { sendAnalytics } from '../index';
import { eventClickerList } from './events';

document.addEventListener('click', e => {
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in eventClickerList) {
    eventClickerList[key].listener = document.querySelectorAll(`[data-analytics=${key}]`);
    if (eventClickerList[key].listener.length && eventClickerList[key].event !== 'onclick') {
      const { event, params } = eventClickerList[key].options;
      // eslint-disable-next-line no-prototype-builtins
      if (eventClickerList[key].hasOwnProperty('isValid')) {
        eventClickerList[key].listener.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item[eventClickerList[key].event] = function itemValidateSendAnalytics(evt) {
            if (eventClickerList[key].isValid(evt)) {
              sendAnalytics(event, params);
            }
          };
        });
      } else {
        eventClickerList[key].listener.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item[eventClickerList[key].event] = function itemSendAnalytics() {
            sendAnalytics(event, params);
          };
        });
      }
    } else if (e.target.dataset && e.target.dataset.analytics === key) {
      const { event, params } = eventClickerList[key].options;
      sendAnalytics(event, params);
    }
  }
});
