<script>
/**
 *  Общий компонент вывода сообщений для всех апп VUE.
 *  Для использования вызвать globalThis.emitter.emit('addNotification', text)
 */
import Popup from 'src/components/Popup';

export default {
  components: {
    Popup,
  },

  data() {
    return {
      showPopup: false,
      notification: '',
      closeCallBack: null,
      hasCallback: false,
    };
  },

  methods: {
    async closePopup() {
      try {
        if (this.hasCallback) {
          await this.closeCallBack();
        }
      } catch (err) {
        // todo - add catch handler
        console.warn(err);
        throw err;
      } finally {
        this.showPopup = false;
        this.notification = '';

        document.body.style.overflow = 'auto';
        // globalThis.emitter.emit('popupClose');
      }
    },

    addNotification(payload) {
      const { message, callBack } = payload;
      this.notification = message ?? payload;
      this.closeCallBack = callBack ?? null;
      this.hasCallback = !!callBack;
    },
  },

  mounted() {
    globalThis.emitter.on('addNotification', this.addNotification);
    // для обратной совместимости
    globalThis.emitter.on('setErrorMessage', this.addNotification);
  },

  watch: {
    notification() {
      if (this.notification) document.body.style.overflow = 'hidden';
    },
  },
};
</script>

<template>
  <Popup class="c-notification" v-if="notification" @close="closePopup">
    {{ notification }}
  </Popup>
</template>
