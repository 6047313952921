var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading || !!_vm.parentLoading
        ? _c(
            "div",
            { staticClass: "v-product-popup__spinner" },
            [_c("LdsSpinner")],
            1
          )
        : [
            _c("div", { staticClass: "added-to-success" }, [
              _c("p", { staticClass: "added-to-cart-popup__title" }, [
                _vm._v("Товар добавлен в корзину")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "added-to-cart-popup__product product--left" },
                [
                  _c("img", {
                    staticClass: "added-to-cart-popup__images",
                    attrs: { src: _vm.product.imageThumbnail, alt: "product" }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "added-to-cart-popup__info-wrapper" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "added-to-cart-popup__text-wrapper added-to-cart-popup__text-wrapper--title"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "added-to-cart-popup__text-info added-to-cart-popup__text-info--name added-to-cart-popup__text-info--title"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.product.name) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "added-to-cart-popup__attributes" },
                            _vm._l(_vm.attributes, function(item) {
                              return _c(
                                "p",
                                {
                                  key: item.id,
                                  staticClass: "added-to-cart-popup__paragraph"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.name) +
                                      " "
                                  ),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.attrValue(item)))
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "added-to-cart-popup__text-left" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "added-to-cart-popup__text-info added-to-cart-popup__text-info--price"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.totalPrice.toLocaleString()) +
                                  " "
                              ),
                              _c("RubSymbol", [_vm._v("И")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "added-to-cart-popup__text-counter"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "product__counter",
                                  attrs: { disabled: _vm.amount === 1 },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.amount !== 1
                                        ? _vm.setAmount(-1)
                                        : false
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "36",
                                        height: "35",
                                        viewBox: "0 0 36 35",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("rect", {
                                        attrs: {
                                          x: "0.416667",
                                          y: "0.416667",
                                          width: "34.2182",
                                          height: "34.1667",
                                          rx: "3.75",
                                          fill: "white",
                                          stroke: "#E2E2E2",
                                          "stroke-width": "0.833333"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          d: "M11.3403 17.5H23.7115",
                                          stroke: "#9A9A9A",
                                          "stroke-width": "0.833333",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "added-to-cart-popup__text-amount"
                                },
                                [_vm._v(_vm._s(_vm.amount))]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "product__counter",
                                  attrs: { disabled: _vm.amount >= 99 },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.setAmount(1)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "36",
                                        height: "35",
                                        viewBox: "0 0 36 35",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("rect", {
                                        attrs: {
                                          x: "1.36491",
                                          y: "0.416667",
                                          width: "34.2182",
                                          height: "34.1667",
                                          rx: "3.75",
                                          fill: "white",
                                          stroke: "#E2E2E2",
                                          "stroke-width": "0.833333"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          d: "M18.4741 11.3235V23.6765",
                                          stroke: "#9A9A9A",
                                          "stroke-width": "0.833333",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          d: "M12.2886 17.5H24.6597",
                                          stroke: "#9A9A9A",
                                          "stroke-width": "0.833333",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "added-to-cart-popup__buttons" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "added-to-cart-popup__button added-to-cart-popup__button--left hide-link-oc",
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "37",
                          height: "27",
                          viewBox: "0 0 37 27"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d:
                              "M24.24 1.37c-.49-.5-1.3-.5-1.81 0-.5.49-.5 1.29 0 1.77l9.2 9.09H1.27a1.27 1.27 0 0 0 0 2.53h30.36l-9.2 9.07c-.5.5-.5 1.31 0 1.79.5.51 1.32.51 1.81 0l11.38-11.23c.5-.49.5-1.3 0-1.78z"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("\n            Продолжить покупки\n          ")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "added-to-cart-popup__button added-to-cart-popup__button--right action",
                    attrs: { onclick: "javascript: self.location = '/cart/'" }
                  },
                  [
                    _vm._v("\n          Перейти в корзину\n          "),
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "37",
                          height: "27",
                          viewBox: "0 0 37 27"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d:
                              "M24.24 1.37c-.49-.5-1.3-.5-1.81 0-.5.49-.5 1.29 0 1.77l9.2 9.09H1.27a1.27 1.27 0 0 0 0 2.53h30.36l-9.2 9.07c-.5.5-.5 1.31 0 1.79.5.51 1.32.51 1.81 0l11.38-11.23c.5-.49.5-1.3 0-1.78z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.filteredRecommendedProduct.length
                ? _c(
                    "div",
                    { staticClass: "added-to-cart-popup__recomend" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "added-to-cart-popup__title added-to-cart-popup__title--blue"
                        },
                        [_vm._v("Добавьте к вашему заказу")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.filteredRecommendedProduct, function(
                        recommendation
                      ) {
                        return _c(
                          "div",
                          {
                            key: recommendation.id,
                            staticClass:
                              "added-to-cart-popup__product added-to-cart-popup__product--recomended"
                          },
                          [
                            _c("img", {
                              staticClass: "added-to-cart-popup__images",
                              attrs: {
                                src: recommendation.image,
                                alt: "product"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "added-to-cart-popup__text-wrapper"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "added-to-cart-popup__text-info added-to-cart-popup__text-info--name"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(recommendation.name) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "added-to-cart-popup__text-info added-to-cart-popup__text-info--price-blue"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "added-to-cart-price",
                                        class: {
                                          "added-to-cart-price--hot": _vm.checkSale(
                                            recommendation
                                          )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              recommendation.formattedPrice
                                            ) +
                                            "\n                "
                                        ),
                                        _c("RubSymbol", [_vm._v("И")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.checkSale(recommendation)
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "added-to-cart-price added-to-cart-price--old"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  recommendation.formattedSalePrice
                                                ) +
                                                " "
                                            ),
                                            _c("RubSymbol", [_vm._v("И")])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "added-to-cart-popup__button-wrapper"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "added-to-cart-button",
                                    class: {
                                      "added-to-cart-button--active": _vm.isAddedProduct(
                                        recommendation.id
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addRecommendationProduct(
                                          recommendation
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.isAddedProduct(recommendation.id)
                                            ? "В корзинe"
                                            : "В корзину"
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.isAddedProduct(recommendation.id)
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "added-to-cart-button-icon",
                                        on: {
                                          click: function($event) {
                                            return _vm.addRecommendationProduct(
                                              recommendation
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "48",
                                              height: "48",
                                              viewBox: "0 0 48 48",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            }
                                          },
                                          [
                                            _c("circle", {
                                              attrs: {
                                                cx: "24",
                                                cy: "24",
                                                r: "24",
                                                fill: "#00AAFA"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d:
                                                  "M17.3131 20.934L18.263 13.1992C18.263 11.4905 19.6604 10.1053 21.3841 10.1053H25.7266C27.4504 10.1053 28.8478 11.4905 28.8478 13.1992L29.7977 20.934M13.5813 16.2931L12.6313 33.8253C12.6313 35.534 14.0287 36.9192 15.7525 36.9192H31.3583C33.082 36.9192 34.4794 35.534 34.4794 33.8253L33.5295 16.2931H13.5813Z",
                                                stroke: "white",
                                                "stroke-width": "1.26316",
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "added-to-cart-button-icon"
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "48",
                                              height: "48",
                                              viewBox: "0 0 48 48",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            }
                                          },
                                          [
                                            _c("circle", {
                                              attrs: {
                                                cx: "24",
                                                cy: "24",
                                                r: "23.3684",
                                                fill: "white",
                                                stroke: "#00AAFA",
                                                "stroke-width": "1.26316"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("line", {
                                              attrs: {
                                                x1: "22.2406",
                                                y1: "30.9895",
                                                x2: "35.6756",
                                                y2: "17.5545",
                                                stroke: "white",
                                                "stroke-width": "1.26"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("line", {
                                              attrs: {
                                                x1: "22.2406",
                                                y1: "30.9895",
                                                x2: "35.6756",
                                                y2: "17.5545",
                                                stroke: "#00AAFA",
                                                "stroke-width": "1.26"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M15 23.6865L22.686 31.3725",
                                                stroke: "white",
                                                "stroke-width": "1.26"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                d: "M15 23.6865L22.686 31.3725",
                                                stroke: "#00AAFA",
                                                "stroke-width": "1.26"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                              ]
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }