var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Popup",
    {
      staticClass: "c-base product-add-to-cart",
      on: { close: _vm.closePopup }
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "v-product-popup__spinner" },
            [_c("LdsSpinner")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showProduct
        ? _c("div", { staticClass: "popup-body" }, [
            _c("div", { staticClass: "v-product-popup__header" }, [
              _c("p", { staticClass: "v-product-popup--title" }, [
                _vm._v(_vm._s(_vm.product.name))
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "v-product-popup--price" },
                [
                  _vm._v(" " + _vm._s(_vm.currentPrice.toLocaleString()) + " "),
                  _c("RubSymbol", [_vm._v("И")])
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "v-product-popup__body" }, [
              _c("div", { staticClass: "v-product-popup--image" }, [
                _c("img", {
                  attrs: { src: _vm.product.imageThumbnail, alt: "product" }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "v-product-popup--attributes" },
                _vm._l(_vm.attributesFiltered, function(attr) {
                  return _c(
                    "div",
                    { key: attr.id, staticClass: "v-product-popup--attribute" },
                    [
                      _c(
                        "div",
                        { staticClass: "v-product-popup--attribute-name" },
                        [_vm._v(_vm._s(attr.name))]
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.attributeValues[attr.id],
                              expression: "attributeValues[attr.id]"
                            }
                          ],
                          staticClass: "v-product-popup--attribute-input",
                          class: { error: attr.error },
                          on: {
                            focus: function($event) {
                              return _vm.clearError(attr)
                            },
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.attributeValues,
                                  attr.id,
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.changeAttribute
                            ]
                          }
                        },
                        _vm._l(attr.values, function(item) {
                          return _c(
                            "option",
                            { key: item.id, domProps: { value: item.id } },
                            [_vm._v(_vm._s(item.value))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.showPromotions
                ? _c("div", { staticClass: "v-product-popup__promo" }, [
                    _vm.width >= 640
                      ? _c("img", {
                          staticClass: "v-product-popup__promo-img",
                          attrs: {
                            src:
                              "/frontend-new/assets/images/promo/__365x142.svg",
                            alt: "Polaroid Promo"
                          }
                        })
                      : _c("img", {
                          staticClass: "v-product-popup__promo-img",
                          attrs: {
                            src:
                              "/frontend-new/assets/images/promo/__250x147.svg",
                            alt: "Polaroid Promo"
                          }
                        })
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "v-product-popup__footer" }, [
              _c(
                "button",
                {
                  attrs: { disabled: _vm.disabledButton },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.addProduct.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Добавить в корзину")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.product.id && !_vm.loading
        ? _c("div", { staticClass: "popup-body" }, [_vm._v("Товар не найден")])
        : _vm._e(),
      _vm._v(" "),
      _vm.showSuccess
        ? _c("SuccessPopup", {
            attrs: {
              amount: +_vm.amount,
              price: _vm.currentPrice,
              product: _vm.product,
              attributeValues: _vm.attributeValues,
              attributes: _vm.attributesFiltered,
              recommendedProduct: _vm.recommendedProduct,
              contextItems: _vm.contextItems,
              contextId: _vm.contextId,
              addedProduct: _vm.addedProduct,
              parentLoading: _vm.loading,
              variantId: _vm.currentVariantId
            },
            on: { addProduct: _vm.addToCart, close: _vm.closePopup }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ErrorNotification")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }