import { Vue } from 'src/apps/vue';
import { createStore } from 'src/apps/store';

window.addEventListener('load', async () => {
  const elem = document.createElement('div');
  const state = window.__VUE_APP_STATE__;
  const store = await createStore(state);

  document.body.appendChild(elem);

  const app = new Vue({
    render: fn => fn(require('./App.vue').default),
    store,
  });

  app.$mount(elem);
});
