// В этом файле должны быть скрипты необходимые на всех страницах сайта
import 'src/utils/eventBus';
import 'src/apps/notification';
// ToDO: вынести из common.js
import 'src/apps/product-add-to-cart';

import '../../frontend/assets/scss/stylesheets.scss';
import '../../frontend/assets/scss/components/block/app-store/app-store.scss';
import '../../frontend/assets/scss/components/block/socials/footer-social.scss';
import '../../frontend/assets/scss/components/catalog/catalog-pager.scss';
import '../../frontend/assets/scss/components/catalog/catalog-snippets.scss';
import '../../frontend/assets/scss/components/checkout/thanks-page.scss';
import '../../frontend/assets/scss/components/footer/footer.scss';
import '../../frontend/assets/scss/components/header/checkout-header-fixed.scss';
import '../../frontend/assets/scss/components/header/header-icon.scss';
import '../../frontend/assets/scss/components/layout/layout-icon.scss';
import '../../frontend/assets/scss/components/layout/order-succes-page.scss';
import '../../frontend/assets/scss/components/layout/product-page_cities.scss';
import '../../frontend/assets/scss/components/shop/shop-icon.scss';
import '../features/common/common.scss';
import '../features/common/fonts.css';
import '../features/minicart/index';
import '../features/sentry/index';
import '../features/any-query/index';
import Analytics from 'src/features/analytics';
import { Vue } from 'src/apps/vue';
import * as persistSmsVerification from 'src/features/persist-sms-verification';
import { waitFor } from 'src/utils/wait-for';
import 'src/utils/analytics/domTriggers';

const SocialIcons = () => import('../apps/product-detail/components/MainBlock/SocialIcons/index.vue');

// TODO: Хак. Надо как-то расшарить функционал с легаси кодом
window.waitFor = waitFor;
window.persistSmsVerification = persistSmsVerification;

document.addEventListener('DOMContentLoaded', () => {
  window.xcart = {
    analytics: Analytics,
  };

  const icons = document.querySelectorAll('.vue-share-icons');
  if (icons.length) {
    icons.forEach(node => {
      new Vue({
        render: h => h(SocialIcons),
      }).$mount(node);
    });
  }
});
