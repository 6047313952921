import { ANALYTICS } from 'src/constants';
import { idForAnalytics } from 'src/utils';

export default {
  namespaced: true,

  actions: {
    async addItemToCart({ dispatch, getters }, opts = {}) {
      const { product, incrementBy = 1, contextId } = opts;
      try {
        const { cart, totalSum } = getters;
        let triggerName = '';

        if (incrementBy > 0) {
          triggerName = ANALYTICS.ITEM_ADD;
        } else {
          triggerName = ANALYTICS.ITEM_REMOVE;
        }

        const productsInCart = cart.map(item => {
          const currentProduct = item.variant ? item.variant === product.variantId : item.id === product.productId;

          return {
            id: item.productId || '',
            name: item.name || '',
            vendor: item.brand || '',
            price: item.price || 0,
            oldPrice: item.oldPrice || 0,
            quantity: currentProduct ? item.amount + 1 : item.amount || 1,
            available: +item.enabled,
            variant: item.variantId,
          };
        });

        const payloadAnalitic = {
          name: product.name,
          id: product.id,
          price: parseInt(String(product.price).replace(/ /g, ''), 10),
          brand: product.brand?.name || '',
          category: product.categories[0]?.name || '',
          variant: product.variantId,
          quantity: 1,
          cart: productsInCart,
          totalSum,
          idForAnalytics: idForAnalytics(product.id, [product.variantId]),
        };

        window.xcore.trigger(triggerName, {
          product: payloadAnalitic,
        });

        if (incrementBy > 0) {
          const payload = {
            productId: product.id,
            attributeValues: product.attributeValues,
            amount: incrementBy,
            contextId,
          };

          await dispatch('checkout/addItem', payload, { root: true });
        } else {
          const payload = {
            id: product.itemId,
            amount: product.amount + incrementBy,
          };

          await dispatch('checkout/changeAmount', payload, { root: true });
        }
      } catch (error) {
        console.error(error);
        const code = error.response?.data?.code;
        const isErrorCode = code !== 0;
        if (isErrorCode) {
          const message =
            error.response?.data?.message || error.message || 'Произошла ошибка при добавлении товара в корзину';
          globalThis.emitter.emit('addNotification', message);
        }
      }
    },
  },

  getters: {
    cart(_s, _g, _rs, rootGetters) {
      return rootGetters['checkout/getContextItems'];
    },

    totalSum(_s, _g, _rs, rootGetters) {
      return rootGetters['checkout/getTotalSum'];
    },
  },
};
