window.xcore.bind('updateCart', (event, data) => {
  const maxCartCount = 99;
  const cartCounters = document.querySelectorAll('.cart-counter');
  if (cartCounters.length && data.itemsCount) {
    cartCounters.forEach(cartCounter => {
      /* eslint-disable no-param-reassign */
      if (data.itemsCount > maxCartCount) {
        cartCounter.innerHTML = maxCartCount;
        return;
      }
      cartCounter.innerHTML = data.itemsCount;
      /* eslint-enable no-param-reassign */
    });
  }
});
