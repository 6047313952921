/**
 * Этот класс описывает фасад аналитики.
 *
 * @class      AnalyticsFacade (name)
 */
class AnalyticsFacade {
  constructor() {
    this.flushListeners();
  }

  flushListeners() {
    this.listeners = {};
  }

  /**
   * Добавляет слушателя.
   *
   * @param      {Array|String}  events    События
   * @param      {Function}  listener  Слушатель
   */
  addListener(events, listener) {
    if (Array.isArray(events)) {
      events.forEach(event => {
        this.addToListener(event, listener);
      });
    } else {
      this.addToListener(events, listener);
    }
  }

  addToListener(event, listener) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(listener);
  }

  /**
   * Вызвать событие, (или несколько) передать в него данные
   *
   * @param      {String}  event   Событие
   * @param      {Object}  data    Данные
   */
  trigger(event, data) {
    if (!(event in this.listeners)) {
      return;
    }

    if (!this.listeners[event].length) {
      return;
    }

    this.listeners[event].forEach(callback => {
      callback.apply(null, [data]);
    });
  }
}

export default AnalyticsFacade;
