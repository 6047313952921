import { http } from 'src/apps/product-detail/utils/http';

class LegacyAPI {
  getCorrectProductPrice = (opts = {}) => {
    const { id, amount, attributeString } = opts;

    return http
      .get(`/?target=productVariantsDisplay&action=getVariantsData&product_id=${id}&amount=${amount}${attributeString}`)
      .then(r => r.data);
  };
}

export const legacyAPI = new LegacyAPI();
