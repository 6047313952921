/* eslint-disable */

import { Vue } from '../../apps/vue';

const sentryDsn = window.__X_CART_DATA__ ? window.__X_CART_DATA__.sentry_front_dsn : '';
const tracesSampleRate = window.__X_CART_DATA__ ? window.__X_CART_DATA__.traces_sample_rate : '';
const sentryRelease = window.__SENTRY_STATE__ ? window.__SENTRY_STATE__.release : '';

async function init() {
  const [ Sentry, Tracing ] = await Promise.all([
    import('@sentry/vue'),
    import('@sentry/tracing'),
  ]);
  Sentry.init({
    Vue,
    dsn: sentryDsn,
    release: sentryRelease || '',
    logErrors: true,
    integrations: [new Tracing.Integrations.BrowserTracing()],
    tracesSampleRate: tracesSampleRate,
    tracingOptions: {
      trackComponents: false,
    },
  });

  Sentry.configureScope((scope) => {
    scope.setTransactionName('GET ' + location.pathname + location.search);
  });

  if (window.__SENTRY_STATE__) {
    const user = window.__SENTRY_STATE__.user;
    if (user) {
      Sentry.setUser(user);
    }
    const tags = window.__SENTRY_STATE__.tags;
    if (tags) {
      Sentry.setTags(tags);
    }
  }
}

if (sentryDsn) {
  window.addEventListener('load', init);
}
