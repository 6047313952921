var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notification
    ? _c(
        "Popup",
        { staticClass: "c-notification", on: { close: _vm.closePopup } },
        [_vm._v("\n  " + _vm._s(_vm.notification) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }