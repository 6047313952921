<script>
export default {};
</script>

<template>
  <span>
    <slot />
  </span>
</template>

<style scoped>
span {
  font-family: 'RUBSN';
}
</style>
