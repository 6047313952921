<script>
/**
 *  Общий компонент ошибки запрсоов для всех апп VUE.
 *  Для использования, необходимо в вызываемом компоненте заимпортить
 *  @params time - через сколько закрыть нотификацию, default = 5 секунд
 *  @
 *  и далее вызвать в этом копоненте globalThis.emitter.emit('setErrorNotify', errorText)
 */

export default {
  name: 'ErrorNotification',

  props: {
    time: {
      type: Number,
      default: 5,
    },
  },

  data: () => ({
    showError: false,
    errorMessage: '',
    showTimeout: '',
    cleanTextTimeout: '',
  }),

  methods: {
    onSetErrorMessage(message) {
      this.errorMessage = message;
      this.showError = true;

      if (this.showTimeout) {
        clearTimeout(this.showTimeout);
      }
      if (this.cleanTextTimeout) {
        clearTimeout(this.cleanTextTimeout);
      }

      this.showTimeout = setTimeout(this.onHideErrorMessage, this.time * 1000);
    },

    onHideErrorMessage() {
      this.showError = false;

      this.cleanTextTimeout = setTimeout(() => {
        this.errorMessage = '';
      }, 1500);
    },
  },

  mounted() {
    globalThis.emitter.on('setErrorNotify', this.onSetErrorMessage);
  },

  destroyed() {
    globalThis.emitter.off('setErrorNotify', this.onSetErrorMessage);
  },
};
</script>

<template>
  <div class="error_notification_container" :class="{ error_notification_container_animate: showError }">
    {{ errorMessage }}
  </div>
</template>

<style scoped>
.error_notification_container {
  position: fixed;
  top: 0;
  left: 50%;
  padding: 5px 10px 5px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 14px;
  line-height: 21px;
  hyphens: none;
  color: white;
  text-align: center;
  background: red;
  transition: all 0.3s ease-in-out;
  transform-origin: left top;
  transform: scaleY(0) translateX(-50%);
  z-index: 9999;
}

.error_notification_container_animate {
  transform: scaleY(1) translateX(-50%);
}
</style>
