var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "c-popup" } }, [
    _c("div", { staticClass: "c-popup-overlay" }, [
      _c("div", { staticClass: "c-popup" }, [
        _c("div", { staticClass: "c-popup__head" }, [
          _vm.showCloseBtn
            ? _c("div", {
                staticClass: "c-popup__close",
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-popup__content" }, [_vm._t("default")], 2)
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }