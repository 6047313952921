<script>
import LdsSpinner from 'src/components/LdsSpinner';
import RubSymbol from 'src/components/RubSymbol';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SuccessHitsPopup',
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    price: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      default: () => {},
    },
    attributeValues: {
      type: Object,
      default: () => {},
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    recommendedProduct: {
      type: Array,
      default: () => [],
    },
    contextItems: {
      type: Array,
      default: () => [],
    },
    contextId: {
      type: String,
      default: '',
    },
    variantId: {
      type: [Number, null],
      default: null,
    },
    addedProduct: {
      type: Array,
      default: () => [],
    },
    parentLoading: {
      type: Number,
      default: 0,
    },
  },
  components: {
    RubSymbol,
    LdsSpinner,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('common', ['isMobile']),
    currentvariantId() {
      return this.variantId === 0 && this.product.variantId ? this.product.variantId : this.variantId;
    },
    totalPrice() {
      return this.price * this.amount;
    },
    filteredRecommendedProduct() {
      if (!this.recommendedProduct.length) return [];

      return this.isMobile ? [this.recommendedProduct[0]] : this.recommendedProduct;
    },
  },
  methods: {
    ...mapActions('productAddToCart', ['addItemToCart']),
    attrValue(item) {
      const selectedValue = item.values.find(val => val.id === this.attributeValues[item.id]);
      return selectedValue.value;
    },
    checkSale(product) {
      return product?.price !== product?.salePrice;
    },
    isAddedProduct(id) {
      const products = [...this.addedProduct, ...this.contextItems];
      return products.find(item => item.productId === id);
    },
    async setAmount(incrementBy) {
      this.loading = true;
      if ((this.amount === 1 && incrementBy < 0) || (this.amount === 99 && incrementBy > 0)) {
        return false;
      }

      const currentProductVariant = this.contextItems.find(item => item.variantId === this.currentvariantId);
      const currentProduct = this.contextItems.find(item => item.productId === this.product.id);
      await this.addItemToCart({
        product: {
          ...this.product,
          amount: this.amount,
          attributeValues: this.attributeValues,
          itemId: currentProductVariant?.itemId || currentProduct?.itemId,
        },
        incrementBy,
        contextId: this.contextId,
      });
      this.loading = false;
    },
    addRecommendationProduct(recommendation) {
      if (!this.isAddedProduct(recommendation.id)) {
        this.$emit('addProduct', recommendation);
      }
    },
  },
};
</script>

<template>
  <div>
    <div v-if="loading || !!parentLoading" class="v-product-popup__spinner">
      <LdsSpinner />
    </div>
    <template v-else>
      <div class="added-to-success">
        <p class="added-to-cart-popup__title">Товар добавлен в корзину</p>
        <div class="added-to-cart-popup__product product--left">
          <img :src="product.imageThumbnail" alt="product" class="added-to-cart-popup__images" />
          <div class="added-to-cart-popup__info-wrapper">
            <div class="added-to-cart-popup__text-wrapper added-to-cart-popup__text-wrapper--title">
              <span
                class="added-to-cart-popup__text-info added-to-cart-popup__text-info--name added-to-cart-popup__text-info--title"
              >
                {{ product.name }}
              </span>
              <div class="added-to-cart-popup__attributes">
                <p v-for="item in attributes" :key="item.id" class="added-to-cart-popup__paragraph">
                  {{ item.name }} <strong>{{ attrValue(item) }}</strong>
                </p>
              </div>
            </div>
            <div class="added-to-cart-popup__text-left">
              <span class="added-to-cart-popup__text-info added-to-cart-popup__text-info--price">
                {{ totalPrice.toLocaleString() }} <RubSymbol>И</RubSymbol>
              </span>
              <div class="added-to-cart-popup__text-counter">
                <button
                  :disabled="amount === 1"
                  @click.prevent.stop="amount !== 1 ? setAmount(-1) : false"
                  class="product__counter"
                >
                  <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="0.416667"
                      y="0.416667"
                      width="34.2182"
                      height="34.1667"
                      rx="3.75"
                      fill="white"
                      stroke="#E2E2E2"
                      stroke-width="0.833333"
                    />
                    <path
                      d="M11.3403 17.5H23.7115"
                      stroke="#9A9A9A"
                      stroke-width="0.833333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <span class="added-to-cart-popup__text-amount">{{ amount }}</span>
                <button :disabled="amount >= 99" @click.prevent="setAmount(1)" class="product__counter">
                  <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="1.36491"
                      y="0.416667"
                      width="34.2182"
                      height="34.1667"
                      rx="3.75"
                      fill="white"
                      stroke="#E2E2E2"
                      stroke-width="0.833333"
                    />
                    <path
                      d="M18.4741 11.3235V23.6765"
                      stroke="#9A9A9A"
                      stroke-width="0.833333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.2886 17.5H24.6597"
                      stroke="#9A9A9A"
                      stroke-width="0.833333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="added-to-cart-popup__buttons">
          <button
            class="added-to-cart-popup__button added-to-cart-popup__button--left hide-link-oc"
            @click="$emit('close')"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="27" viewBox="0 0 37 27">
              <path
                fill="currentColor"
                d="M24.24 1.37c-.49-.5-1.3-.5-1.81 0-.5.49-.5 1.29 0 1.77l9.2 9.09H1.27a1.27 1.27 0 0 0 0 2.53h30.36l-9.2 9.07c-.5.5-.5 1.31 0 1.79.5.51 1.32.51 1.81 0l11.38-11.23c.5-.49.5-1.3 0-1.78z"
              />
            </svg>
            <span>
              Продолжить покупки
            </span>
          </button>
          <button
            class="added-to-cart-popup__button added-to-cart-popup__button--right action"
            onclick="javascript: self.location = '/cart/'"
          >
            Перейти в корзину
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="27" viewBox="0 0 37 27">
              <path
                fill="currentColor"
                d="M24.24 1.37c-.49-.5-1.3-.5-1.81 0-.5.49-.5 1.29 0 1.77l9.2 9.09H1.27a1.27 1.27 0 0 0 0 2.53h30.36l-9.2 9.07c-.5.5-.5 1.31 0 1.79.5.51 1.32.51 1.81 0l11.38-11.23c.5-.49.5-1.3 0-1.78z"
              />
            </svg>
          </button>
        </div>
        <div v-if="filteredRecommendedProduct.length" class="added-to-cart-popup__recomend">
          <p class="added-to-cart-popup__title added-to-cart-popup__title--blue">Добавьте к вашему заказу</p>

          <div
            v-for="recommendation of filteredRecommendedProduct"
            :key="recommendation.id"
            class="added-to-cart-popup__product added-to-cart-popup__product--recomended"
          >
            <img :src="recommendation.image" alt="product" class="added-to-cart-popup__images" />
            <div class="added-to-cart-popup__text-wrapper">
              <span class="added-to-cart-popup__text-info added-to-cart-popup__text-info--name">
                {{ recommendation.name }}
              </span>
              <span class="added-to-cart-popup__text-info added-to-cart-popup__text-info--price-blue">
                <span class="added-to-cart-price" :class="{ 'added-to-cart-price--hot': checkSale(recommendation) }">
                  {{ recommendation.formattedPrice }}
                  <RubSymbol>И</RubSymbol>
                </span>
                <span v-if="checkSale(recommendation)" class="added-to-cart-price added-to-cart-price--old">
                  {{ recommendation.formattedSalePrice }} <RubSymbol>И</RubSymbol>
                </span>
              </span>
            </div>
            <div class="added-to-cart-popup__button-wrapper">
              <button
                class="added-to-cart-button"
                :class="{ 'added-to-cart-button--active': isAddedProduct(recommendation.id) }"
                @click="addRecommendationProduct(recommendation)"
              >
                {{ isAddedProduct(recommendation.id) ? 'В корзинe' : 'В корзину' }}
              </button>
              <button
                v-if="!isAddedProduct(recommendation.id)"
                class="added-to-cart-button-icon"
                @click="addRecommendationProduct(recommendation)"
              >
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="#00AAFA" />
                  <path
                    d="M17.3131 20.934L18.263 13.1992C18.263 11.4905 19.6604 10.1053 21.3841 10.1053H25.7266C27.4504 10.1053 28.8478 11.4905 28.8478 13.1992L29.7977 20.934M13.5813 16.2931L12.6313 33.8253C12.6313 35.534 14.0287 36.9192 15.7525 36.9192H31.3583C33.082 36.9192 34.4794 35.534 34.4794 33.8253L33.5295 16.2931H13.5813Z"
                    stroke="white"
                    stroke-width="1.26316"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <button v-else class="added-to-cart-button-icon">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="23.3684" fill="white" stroke="#00AAFA" stroke-width="1.26316" />
                  <line x1="22.2406" y1="30.9895" x2="35.6756" y2="17.5545" stroke="white" stroke-width="1.26" />
                  <line x1="22.2406" y1="30.9895" x2="35.6756" y2="17.5545" stroke="#00AAFA" stroke-width="1.26" />
                  <path d="M15 23.6865L22.686 31.3725" stroke="white" stroke-width="1.26" />
                  <path d="M15 23.6865L22.686 31.3725" stroke="#00AAFA" stroke-width="1.26" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
