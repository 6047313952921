var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowedPopup
    ? _c("ProductPopup", {
        attrs: {
          productId: _vm.productId,
          variantId: _vm.variantId,
          backendType: _vm.backendType,
          showPopup: _vm.showPopup
        },
        on: { close: _vm.closePopup }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }