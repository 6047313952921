import { Vue } from 'src/apps/vue';

window.addEventListener('load', async () => {
  const elem = document.createElement('div');

  document.body.appendChild(elem);

  const app = new Vue({
    render: fn => fn(require('./App.vue').default),
  });

  app.$mount(elem);
});
