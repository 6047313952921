const cartState = {};

function addToCart(offerId) {
  return new Promise(function(resolve) {
    globalThis.emitter.emit('addToCartButton', { id: offerId, backendType: 'any-query' });
    resolve(true);
  });
}

window.dataLayer = window.dataLayer || [];
window.dataLayer.cartState = cartState;
window.dataLayer.addToCart = addToCart;
