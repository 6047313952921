<script>
import ProductPopup from './components/ProductPopup';
import store from './store';

export default {
  components: {
    ProductPopup,
  },

  data() {
    return {
      isShowedPopup: false,
      backendType: 'product',
      productId: null,
      variantId: null,
      showPopup: true,
    };
  },
  methods: {
    closePopup() {
      this.isShowedPopup = false;
    },
    openPopup({ id, variantId, backendType, showPopup }) {
      this.isShowedPopup = true;
      this.productId = id;
      this.variantId = variantId;
      this.showPopup = showPopup;
      this.backendType = backendType;
    },
  },
  mounted() {
    globalThis.emitter.on('addToCartButton', this.openPopup);
  },

  created() {
    this.$store.registerModule('productAddToCart', store, {
      preserveState: typeof window !== 'undefined',
    });
  },
};
</script>

<template>
  <ProductPopup
    v-if="isShowedPopup"
    :productId="productId"
    :variantId="variantId"
    :backendType="backendType"
    :showPopup="showPopup"
    @close="closePopup"
  />
</template>
